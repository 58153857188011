<template>
  <div
    class="
      bg-blue
      text-white text-center
      q-pa-md
      flex
      fixed-center
      justify-content-center
    "
  >
    <div>
      <div
        style="font-size: 30vh"
        class="text-primary"
      >
        404
      </div>

      <div
        class="text-h2 text-black"
        style="opacity: 0.4"
      >
        Oops. Nothing here...
      </div>

      <Button
        @click="navHome()"
        class="m-4"
        label="Go Home"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Error404",
  methods: {
    navHome() {
      this.$router.replace("/");
    },
  },
};
</script>
